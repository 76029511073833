var img_url = "/images/pc/snail_logo_img-53203c2ef5.png";
var label_1 = "※App Store, iTunesは米国およびその他の国々で登録されているApple Inc.の商標です。";
var label_2 = "※Google Play および Google Play ロゴは Google LLC の商標です。";
var info_1  = "QCPlay，All rights reserved.";
var info_2  = "〒110-0016 東京都台東区台東1-24-9 ブライト秋葉原7F";

// pc端
var footerHtml = '<div class="footer-content">'+
    '<div class="left-logo"><img src='+img_url+'></div>'+
    '<div class="centre-info">'+
        '<div class="info">'+
            '<p class="label_1">'+label_1+'</p>'+
            '<p class="label_2">'+label_2+'</p>'+
        '</div>'+
    '</div>'+
    '<div class="right-details">'+
        '<p>'+
            '<a target="_blank" href="faq.html">FAQ</a>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;'+
            '<a target="_blank" href="https://law.qingcigame.com/ja-jp/service_agreement.html">利用規約</a>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;'+
            '<a target="_blank" href="https://law.qingcigame.com/ja-jp/privacy_policy.html">プライバシーポリシー</a>'+            
        '</p>'+
        '<p>'+
            '<a target="_blank" href="https://law.qingcigame.com/ja-jp/commercial_law.html">特定商取引法に基づく表示</a>&nbsp;&nbsp;|&nbsp;&nbsp;'+
            '<a target="_blank" href="https://law.qingcigame.com/ja-jp/economic_law.html">資金決済法に基づく表示</a>&nbsp;&nbsp;|&nbsp;&nbsp;'+
            '<span>'+info_1+'</span>'+
        '</p>'+
    '</div>'+
'</div>';
$('.footer').append(footerHtml);

// 移动端
var mFooterHtml = '<div>'+
    '<p class="friendship-link">'+
        '<a style="margin: 0 auto;" target="_blank" href="https://snail.gamer.cc/"></a>'+
    '</p>'+
    '<p>'+
        '<a target="_blank" href="https://law.qingcigame.com/ja-jp/economic_law.html">資金決済法に基づく表示</a>'+
    '</p>'+
    '<p>'+
        '<a target="_blank" href="https://law.qingcigame.com/ja-jp/commercial_law.html">特定商取引法に基づく表示</a>'+
    '<p><a target="_blank" href="https://law.qingcigame.com/ja-jp/service_agreement.html">利用規約</a>&nbsp;&nbsp;&nbsp;<a target="_blank" href="https://law.qingcigame.com/ja-jp/privacy_policy.html">プライバシーポリシー</a></p>'+
    '</p>'+
    '<p>'+info_1+'</p>'+
'</div>';
$('.m-footer').append(mFooterHtml);
